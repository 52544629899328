<template>
  <div>
    <div class="md-title">Create a Link</div>
    <div class="md-subheading">Preview: mindsupport.eu/r/{{ link }}</div>
    <md-field>
      <label>Referral Name</label>
      <md-input v-model="name"></md-input>
      <span class="md-helper-text">This will be only visible to you.</span>
    </md-field>
    <md-field>
      <label>Referral Link</label>
      <md-input v-model="link"></md-input>
      <span class="md-helper-text">This is the code that will be part of the link.</span>
      <md-button @click="link = generateLink()">Generate for me</md-button>
    </md-field>
    <div style="width:100%; text-align:right">
      <md-button class="md-raised md-primary" @click="createLink()">Generate Link</md-button>
    </div>
    <div v-if="chartData">
      <div class="md-title">Clicks per link</div>
      <bar-chart
        style="height: 300px;"
        :chart-data="chartData"
        :options="chartOptions"/>
    </div>
    <div v-if="chartData">
      <div class="md-title" >Details</div>
      <DBTable
        title="Link Details"
        :load-path="log_address"
        :delete-path="log_address"
        :log="true"
        :show-index="false"
        :columns="{'Name':'referralName', 'Link (suffix for mindsupport.eu/r/)':'referral', 'Clicks':'clicks'}"
      />
    </div>
  </div>
</template>
<script>
import config from '@/config'
import BarChart from '@/components/charts/bar'
import DBTable from '@/components/DBTable'
import axios from "axios";
export default {
  name: 'Referral',
  data: function () {
    return {
      link: '',
      name: '',

      chartData: null,
      chartOptions:null,
      log_address: config.LOGGER + '/referral/'
    }
  },
  methods: {
    createLink: function (){
      if(!this.name){
        alert('Fill in name, please.')
        return
      }
      if(!this.link){
        alert('Fill in link, please.')
        return
      }
      const data = {
        "time": Date.now(),
        "referral": this.link,
        "referralName": this.name
      }
      axios.post(config.LOGGER + '/referral',data).then(() => {
        alert(`Your link is:\nmindsupport.eu/r/${this.link}`)
        this.link = this.name = ''
        this.loadData()
      }).catch(e => {
        console.warn(e)
        alert(`Error creating your link:\n ${e}`)
      })
    },
    generateLink: function () {
      const length = 4,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
      let retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },
    loadData: async function () {
      const data = (await axios.get(config.LOGGER + '/referral')).data
      if(!data.length)
        return

      this.chartData = {
        labels: data.map(i => i.referralName),
        datasets: [{
          data: data.map(i => i.clicks),
          label: 'Clicks per Link',
          backgroundColor: '#4791db',
        }]
      }
      this.chartOptions = {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted (){
    this.loadData();
  },
  components: {BarChart, DBTable}
}
</script>
