<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  mounted () {
    this.renderChart(this.myData, this.options)
  },
  computed: {
    myData: function() {
      return this.chartData;
    }
  },
  watch: {
    chartData: function() {
      this.$data._chart.destroy()
      console.log('updating')
      this.renderChart(this.myData, this.options)
    }
  }
}
</script>
